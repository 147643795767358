import { alpha } from '@mui/system'

export const buttonFontSize = 14
export const buttonIconFontSizeMedium = 20

const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				fontFamily: 'var(--button-font-family)',
				fontSize: 'var(--button-primary-size)',
				fontWeight: 'var(--button-font-weight)',
				letterSpacing: '0.17px',
				padding: '8px 20px',
				borderRadius: 0,
				minHeight: 50,
			},
			sizeMedium: {
				'& .MuiButton-iconSizeMedium.MuiButton-startIcon': {
					'& .MuiSvgIcon-fontSizeSmall': {
						fontSize: '1.4rem',
					},
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: '1.7rem',
					},
					'& .MuiSvgIcon-fontSizeLarge': {
						fontSize: '1.7rem',
					},
				},
			},
			text: {
				textTransform: 'none',
				paddingBottom: '1px',
				minHeight: 'auto',
			},
			containedPrimary: {
				minHeight: 50,
				border: '1px solid var(--color-white)',
			},
			containedSecondary: {
				borderWidth: '1px',
				color: 'var(--button-secondary-color)',
				minHeight: 50,
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--button-secondary-hover-bgcolor)',
					color: 'var(--button-secondary-hover-color)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'var(--button-secondary-hover-bgcolor)',
					color: 'var(--button-secondary-hover-color)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--button-secondary-hover-bgcolor)',
					color: 'var(--button-secondary-hover-color)',
				},
				'&.Mui-disabled': {
					color: 'var(--button-secondary-color)',
					backgroundColor: alpha('#000000', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--button-secondary-color)',
				},
				'& >svg': {
					color: 'var(--button-secondary-color)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--button-secondary-color)',
				},
			},
			outlined: {
				borderRadius: '0px',
				borderWidth: '0.5px',
				padding: '8px 20px',
				minHeight: 50,
				'&:hover': {
					borderWidth: '0.5px',
				},
				'&:focus': {
					borderWidth: '0.5px',
				},
				'&:active': {
					borderWidth: '0.5px',
				},
				'&.Mui-disabled': {
					borderWidth: '0.5px',
				},
			},
			outlinedPrimary: {
				color: 'var(--color-black)',
				background: 'var(--color-white)',
				borderColor: 'var(--color-black)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
					color: 'var(--color-white)',
					borderColor: 'var(--color-black)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-black)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--color-black)',
				},
				'& >svg': {
					color: 'var(--color-black)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--color-black)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-white)',
				background: 'transparent',
				borderColor: 'var(--color-white)',
				'&:hover': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-white)',
					color: 'var(--color-black)',
					borderColor: 'var(--color-white)',
				},
				'&:focus': {
					boxShadow: 'none',
					backgroundColor: 'transparent',
					borderColor: 'var(--color-white)',
				},
				'&:active': {
					boxShadow: 'none',
					backgroundColor: 'var(--color-white)',
					borderColor: 'var(--color-white)',
					color: 'var(--color-black)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-black)',
					backgroundColor: alpha('#ffffff', '0.2'),
				},
				'& .MuiButton-startIcon': {
					fill: 'var(--color-white)',
				},
				'& >svg': {
					color: 'var(--color-white)',
				},
				'& .MuiButton-endIcon': {
					fill: 'var(--color-white)',
				},
			},
			contained: {
				borderRadius: 0,
			},
			textNoLinkPrimary: {
				fontFamily: 'var(--button-font-family)',
				fontWeight: 'var(--font-weight-regular)',
				color: 'var(--color-black)',
			},
			textSecondary: {
				'&:hover': {
					color: alpha('#ffffff', '0.8'),
					textDecoration: 'underline',
				},
			},
			startIcon: {
				marginLeft: 0,
			},
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				borderWidth: '1px',
			},
			standard: {
				minHeight: 15,
				minWidth: 15,
				transform: 'scale(1) translate(50%, -35%)',
				fontSize: '7px',
			},
		},
	},
}

export default Button
