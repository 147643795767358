import defaultTheme from '@bluheadless/ui/src/theme/default'
import { alpha } from '@mui/system'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root': {
				// spacing
				'--spacing': '8px',
				'--spacing-1': 'calc(var(--spacing) * 1)',
				'--spacing-2': 'calc(var(--spacing) * 2)',
				'--spacing-3': 'calc(var(--spacing) * 3)',
				'--spacing-4': 'calc(var(--spacing) * 4)',
				'--spacing-5': 'calc(var(--spacing) * 5)',
				'--spacing-6': 'calc(var(--spacing) * 6)',
				'--spacing-7': 'calc(var(--spacing) * 7)',
				'--spacing-8': 'calc(var(--spacing) * 8)',
				'--spacing-9': 'calc(var(--spacing) * 9)',
				'--spacing-10': 'calc(var(--spacing) * 10)',
				// shape
				'--border-radius': '4px',
				// font-size
				'--font-size-headlinebig': defaultTheme.typography.pxToRem(90),
				'--font-size-headlinemedium': defaultTheme.typography.pxToRem(86),
				'--font-size-headlinesmall': defaultTheme.typography.pxToRem(78),
				'--font-size-subheadline1': defaultTheme.typography.pxToRem(24),
				'--font-size-subheadline2': defaultTheme.typography.pxToRem(14),
				'--font-size-h1': defaultTheme.typography.pxToRem(30),
				'--font-size-h2': defaultTheme.typography.pxToRem(24),
				'--font-size-h3': defaultTheme.typography.pxToRem(20),
				'--font-size-h4': defaultTheme.typography.pxToRem(18),
				'--font-size-h5': defaultTheme.typography.pxToRem(16),
				'--font-size-h6': defaultTheme.typography.pxToRem(14),
				'--font-size-body1': defaultTheme.typography.pxToRem(12),
				'--font-size-body2': defaultTheme.typography.pxToRem(14),
				'--font-size-button': defaultTheme.typography.pxToRem(14),
				'--font-size-caption': defaultTheme.typography.pxToRem(12),
				'--font-size-subtitle1': defaultTheme.typography.pxToRem(14),
				// font-family
				'--font-gothic': '"Gothic", sans-serif',
				'--font-family-regular': 'var(--font-gothic)',
				'--font-weight-regular': '400',
				// colors
				// brand
				'--color-brand': '#000000',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				// default
				'--color-primary': 'var(--color-black)',
				'--color-secondary': 'var(--color-white)',
				'--color-disabled': '#A8A8A8',
				'--border-color': '#EAEAEA',
				'--color-red': '#D00A26',
				'--color-grey': '#676767',
				'--color-grey-icon': '#B1B1B1',
				// buttons
				'--button-font-family': 'var(--font-family-regular)',
				'--button-font-weight': '600',
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-color': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-white)',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				'--button-secondary-hover-color': 'var(--color-white)',
				'--button-secondary-hover-bgcolor': 'var(--color-black)',
				'--button-ghost-color': 'var(--color-secondary)',
				'--button-ghost-bgcolor': alpha('#000', 0.2),
				'--button-ghost-hover-color': 'var(--color-primary)',
				'--button-ghost-hover-bgcolor': 'var(--color-white)',
				'--button-ghost-secondary-color': 'var(--color-primary)',
				'--button-ghost-secondary-bgcolor': alpha('#fff', 0.4),
				'--button-ghost-secondary-hover-color': 'var(--color-primary)',
				'--button-ghost-secondary-hover-bgcolor': 'var(--color-white)',
				'--button-disabled-color': '--color-disabled',
				'--button-border-radius': '100px',
				// animations
				'--transition-duration': '0.3s',
				'--transition-timing-function': 'cubic-bezier(0.4, 0, 0.2, 1)',
				'--transition-delay': '0s',
				'--transition': 'var(--transition-duration) var(--transition-timing-function) var(--transition-delay)',
				'--swiper-theme-color': 'var(--color-primary)',
				// maps
				'--maps-cluster-bg-color': 'var(--color-brand)',
				// accessibility
				'--accessibility-focus-color': '#5E9ED6',
				'--accessibility-focus-style': 'solid',
				'--accessibility-focus-width': '2px',
				'--accessibility-focus-outline':
					'var(--accessibility-focus-color) var(--accessibility-focus-style) var(--accessibility-focus-width)',
				[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
					'--font-size-h1': defaultTheme.typography.pxToRem(40),
				},
				[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
					'--font-size-h2': defaultTheme.typography.pxToRem(40),
					'--font-size-h3': defaultTheme.typography.pxToRem(18),
					'--font-size-h4': defaultTheme.typography.pxToRem(22),
					'--font-size-h5': defaultTheme.typography.pxToRem(20),
					'--font-size-h6': defaultTheme.typography.pxToRem(16),
				},
			},
			html: {
				fontFamily: 'var(--font-family-regular)',
				fontWeight: 'var(--font-weight-regular)',
				'--layout-max-width': '1440px',
				'--layout-line-height-small': '15px',
				'--layout-line-height-18': '18px',
				'--layout-line-height-medium': '22px',
				'--layout-line-height-big': '26px',
				'--hero-gradient':
					'transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box',
				'--hero-gradient-opacity': '0.7',
			},
			':focus, :focus-visible': {
				outline: 'none',
			},
			':focus-visible': {
				outline: 'var(--accessibility-focus-outline) !important',
			},
			body: {
				fontFamily: 'var(--font-family-regular)',
				fontWeight: 'var(--font-weight-regular)',
				'& .text-white': {
					color: 'var(--color-white)',
				},
				'& .text-black': {
					color: 'var(--color-black)',
				},
				'& .MuiLink-outlinedPrimary': {
					padding: '13px',
					border: '1px solid var(--color-black)',
					color: 'var(--color-black)',
					display: 'inline-block',
					width: '100%',
					maxWidth: '176px',
					textAlign: 'center',
					textDecoration: 'none',
					fontWeight: '600',
					backgroundColor: 'var(--color-white)',
					transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
					'&:hover': {
						backgroundColor: 'var(--color-black)',
						color: 'var(--color-white)',
					},
				},
				'& .MuiLink-outlinedSecondary': {
					padding: '13px',
					border: '1px solid var(--color-white)',
					color: 'var(--color-white)',
					display: 'inline-block',
					width: '100%',
					maxWidth: '176px',
					textAlign: 'center',
					textDecoration: 'none',
					fontWeight: '600',
					backgroundColor: 'var(--color-black)',
					transition: 'all .2s cubic-bezier(0.0, 0, 0.2, 1)',
					'&:hover': {
						backgroundColor: 'var(--color-white)',
						color: 'var(--color-black)',
					},
				},
				'.align-center': {
					textAlign: 'center',
					'&-m': {
						textAlign: 'left',
						[`@media screen and (max-width: ${defaultTheme.breakpoints.values.md - 1}px)`]: {
							textAlign: 'center',
						},
					},
					'&-d': {
						textAlign: 'left',
						[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
							textAlign: 'center',
						},
					},
				},
			},
		},
	},
}

export default CssBaseline
